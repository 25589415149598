module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":false,"isBaseProvider":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://petchemist-wp.madpaws.com.au/graphql","schema":{"typePrefix":"Wp","perPage":50,"requestConcurrency":8,"previewRequestConcurrency":3,"timeout":100000,"queryDepth":15,"circularQueryLimit":5},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"type":{"Post":{"limit":5000},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"graphql":{"showQueryOnError":true,"showQueryVarsOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"iygaXvpbEknmt0K7oP0dxWSsEqNgLK3e","devKey":"iygaXvpbEknmt0K7oP0dxWSsEqNgLK3e","trackPage":false,"trackPageImmediately":true,"trackPageOnlyIfReady":true,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":1000,"trackPageWithTitle":true,"delayLoad":true,"delayLoadDelay":1000,"delayLoadUntilActivity":false,"delayLoadUntilActivityAdditionalDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
